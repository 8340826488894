<template>
  <basic-layout header="Library Section">
    <div class="bg-white bg-opacity-90">
      <div class="py-10 md:py-20">
        <section class="">
          <container class="py-20">
            <div class="sm:text-right pr-2 mb-6 flex items-center sm:p-6 p-4">
              <div>
                <h1 class="text-green-700 md:text-6xl  text-5xl lg:text-8xl font-bold uppercase">Technical</h1>
                <p class="font-thin text-4xl block uppercase ">Section</p>
              </div>
            </div>
            <div class="lg:flex justify-center">
              <div class="m-4 relative rounded-2xl shadow-2xl h-96 w-80 lg:w-80 sm:w-full  border-2 border-white overflow-hidden">
                <img class="h-96  lg:w-80 w-full object-cover object-center"  src="../../assets/images/library-section/main-technical.jpg" title="Main Campus" alt="Main Campus">
                <div class="absolute bottom-0 w-full text-center bg-green-700 bg-opacity-40 py-4">
                  <p class="text-xl text-white">Main Campus</p>
                </div>
              </div>
              <div class="m-4 relative rounded-2xl shadow-2xl h-96 w-80 lg:w-80 sm:w-full  border-2 border-white overflow-hidden">
                <img class="h-96  lg:w-80 w-full object-cover object-center" src="../../assets/images/library-section/calapan-technical.jpg" title=">Calapan City Campus" alt=">Calapan City Campus">
                <div class="absolute bottom-0 w-full text-center bg-green-700 bg-opacity-40 py-4">
                  <p class="text-xl text-white">Calapan City Campus</p>
                </div>
              </div>
              <div class="m-4 relative rounded-2xl  shadow-2xl h-96 w-80 lg:w-80 sm:w-full  border-2 border-white overflow-hidden">
                <img class="h-96  lg:w-80 w-full object-cover object-center" src="../../assets/images/library-section/bongabong-technical.jpg" title=">Bongabong Campus" alt=">Bongabong Campus">
                <div class="absolute bottom-0 w-full text-center bg-green-700 bg-opacity-40 py-4">
                  <p class="text-xl text-white">Bongabong Campus</p>
                </div>
              </div>
            </div>
          </container>
        </section>
        <section class="bg-gray-700 bg-opacity-50">
          <container class="py-20">
            <div class="sm:text-right pr-2 mb-6 flex justify-end items-center sm:p-6 p-4">
              <div>
                <h1 class="text-green-700 md:text-6xl  text-5xl lg:text-8xl font-bold uppercase">Filipiniana</h1>
                <p class="font-thin text-4xl block uppercase ">Section</p>
              </div>
            </div>
            <div class="lg:flex justify-center">
              <div class="m-4 relative rounded-2xl shadow-2xl h-96 w-80 lg:w-80 sm:w-full  border-2 border-white overflow-hidden">
                <img class="h-96  lg:w-80 w-full object-cover object-center"  src="../../assets/images/library-section/main-filipiniana.jpg" title="Main Campus" alt="Main Campus">
                <div class="absolute bottom-0 w-full text-center bg-green-700 bg-opacity-40 py-4">
                  <p class="text-xl text-white">Main Campus</p>
                </div>
              </div>
              <div class="m-4 relative rounded-2xl shadow-2xl h-96 w-80 lg:w-80 sm:w-full  border-2 border-white overflow-hidden">
                <img class="h-96  lg:w-80 w-full object-cover object-center" src="../../assets/images/library-section/calapan-filipiniana.jpg" title=">Calapan City Campus" alt=">Calapan City Campus">
                <div class="absolute bottom-0 w-full text-center bg-green-700 bg-opacity-40 py-4">
                  <p class="text-xl text-white">Calapan City Campus</p>
                </div>
              </div>
              <div class="m-4 relative rounded-2xl  shadow-2xl h-96 w-80 lg:w-80 sm:w-full  border-2 border-white overflow-hidden">
                <img class="h-96  lg:w-80 w-full object-cover object-center" src="../../assets/images/library-section/bongabong-filipiniana.jpg" title=">Bongabong Campus" alt=">Bongabong Campus">
                <div class="absolute bottom-0 w-full text-center bg-green-700 bg-opacity-40 py-4">
                  <p class="text-xl text-white">Bongabong Campus</p>
                </div>
              </div>
            </div>
          </container>
        </section>
        <section class="">
          <container class="py-20">
            <div class="sm:text-right pr-2 mb-6 flex justify-center items-center sm:p-6 p-4">
              <div>
                <h1 class="text-green-700 md:text-6xl  text-5xl lg:text-8xl font-bold uppercase">Circulation</h1>
                <p class="font-thin text-4xl block uppercase ">Section</p>
              </div>
            </div>
            <div class="lg:flex justify-center">
              <div class="m-4 relative rounded-2xl shadow-2xl h-96 w-80 lg:w-80 sm:w-full  border-2 border-white overflow-hidden">
                <img class="h-96  lg:w-80 w-full object-cover object-center"  src="../../assets/images/library-section/main-circulation.jpg" title="Main Campus" alt="Main Campus">
                <div class="absolute bottom-0 w-full text-center bg-green-700 bg-opacity-40 py-4">
                  <p class="text-xl text-white">Main Campus</p>
                </div>
              </div>
              <div class="m-4 relative rounded-2xl shadow-2xl h-96 w-80 lg:w-80 sm:w-full  border-2 border-white overflow-hidden">
                <img class="h-96  lg:w-80 w-full object-cover object-center" src="../../assets/images/library-section/calapan-circulation.jpg" title=">Calapan City Campus" alt=">Calapan City Campus">
                <div class="absolute bottom-0 w-full text-center bg-green-700 bg-opacity-40 py-4">
                  <p class="text-xl text-white">Calapan City Campus</p>
                </div>
              </div>
              <div class="m-4 relative rounded-2xl  shadow-2xl h-96 w-80 lg:w-80 sm:w-full  border-2 border-white overflow-hidden">
                <img class="h-96  lg:w-80 w-full object-cover object-center" src="../../assets/images/library-section/bongabong-circulation.jpg" title=">Bongabong Campus" alt=">Bongabong Campus">
                <div class="absolute bottom-0 w-full text-center bg-green-700 bg-opacity-40 py-4">
                  <p class="text-xl text-white">Bongabong Campus</p>
                </div>
              </div>
            </div>
          </container>
        </section>
        <section class="">
          <container class="py-20">
            <div class="sm:text-right pr-2 mb-6 flex  items-center sm:p-6 p-4">
              <div>
                <h1 class="text-green-700 md:text-6xl  text-5xl lg:text-8xl font-bold uppercase">Reference</h1>
                <p class="font-thin text-4xl block uppercase ">Section</p>
              </div>
            </div>
            <div class="lg:flex justify-center">
              <div class="m-4 relative rounded-2xl shadow-2xl h-96 w-80 lg:w-80 sm:w-full  border-2 border-white overflow-hidden">
                <img class="h-96  lg:w-80 w-full object-cover object-center"  src="../../assets/images/library-section/main-reference.jpg" title="Main Campus" alt="Main Campus">
                <div class="absolute bottom-0 w-full text-center bg-green-700 bg-opacity-40 py-4">
                  <p class="text-xl text-white">Main Campus</p>
                </div>
              </div>
              <div class="m-4 relative rounded-2xl shadow-2xl h-96 w-80 lg:w-80 sm:w-full  border-2 border-white overflow-hidden">
                <img class="h-96  lg:w-80 w-full object-cover object-center" src="../../assets/images/library-section/calapan-reference.jpg" title=">Calapan City Campus" alt=">Calapan City Campus">
                <div class="absolute bottom-0 w-full text-center bg-green-700 bg-opacity-40 py-4">
                  <p class="text-xl text-white">Calapan City Campus</p>
                </div>
              </div>
              <div class="m-4 relative rounded-2xl  shadow-2xl h-96 w-80 lg:w-80 sm:w-full  border-2 border-white overflow-hidden">
                <img class="h-96  lg:w-80 w-full object-cover object-center" src="../../assets/images/library-section/bongabong-reference.jpg" title=">Bongabong Campus" alt=">Bongabong Campus">
                <div class="absolute bottom-0 w-full text-center bg-green-700 bg-opacity-40 py-4">
                  <p class="text-xl text-white">Bongabong Campus</p>
                </div>
              </div>
            </div>
          </container>
        </section>
        <section class="bg-green-800 bg-opacity-30">
          <container class="py-20">
            <div class="sm:text-right pr-2 mb-6 flex  items-center sm:p-6 p-4">
              <div>
                <h1 class="text-green-700 md:text-6xl  text-5xl lg:text-8xl font-bold uppercase">Reserve</h1>
                <p class="font-thin text-4xl block uppercase ">Section</p>
              </div>
            </div>
            <div class="lg:flex justify-center">
              <div class="m-4 relative rounded-2xl shadow-2xl h-96 w-80 lg:w-80 sm:w-full  border-2 border-white overflow-hidden">
                <img class="h-96  lg:w-80 w-full object-cover object-center"  src="../../assets/images/library-section/main-reserve.jpg" title="Main Campus" alt="Main Campus">
                <div class="absolute bottom-0 w-full text-center bg-green-700 bg-opacity-40 py-4">
                  <p class="text-xl text-white">Main Campus</p>
                </div>
              </div>
            </div>
          </container>
        </section>
        <section class="">
          <container class="py-20">
            <div class="sm:text-right pr-2 mb-6 flex justify-center items-center sm:p-6 p-4">
              <div>
                <h1 class="text-green-700 md:text-6xl  text-5xl lg:text-8xl font-bold uppercase">PERIODICAL</h1>
                <p class="font-thin text-4xl block uppercase ">Section</p>
              </div>
            </div>
            <div class="lg:flex justify-center">
              <div class="m-4 relative rounded-2xl shadow-2xl h-96 w-80 lg:w-80 sm:w-full  border-2 border-white overflow-hidden">
                <img class="h-96  lg:w-80 w-full object-cover object-center" src="../../assets/images/library-section/main-periodical.jpg" title=">Main Campus" alt=">Calapan City Campus">
                <div class="absolute bottom-0 w-full text-center bg-green-700 bg-opacity-40 py-4">
                  <p class="text-xl text-white">Main Campus</p>
                </div>
              </div>
              <div class="m-4 relative rounded-2xl shadow-2xl h-96 w-80 lg:w-80 sm:w-full  border-2 border-white overflow-hidden">
                <img class="h-96  lg:w-80 w-full object-cover object-center" src="../../assets/images/library-section/calapan-periodical1.jpg" title=">Calapan City Campus" alt=">Calapan City Campus">
                <div class="absolute bottom-0 w-full text-center bg-green-700 bg-opacity-40 py-4">
                  <p class="text-xl text-white">Calapan City Campus</p>
                </div>
              </div>
              <div class="m-4 relative rounded-2xl shadow-2xl h-96 w-80 lg:w-80 sm:w-full  border-2 border-white overflow-hidden">
                <img class="h-96  lg:w-80 w-full object-cover object-center" src="../../assets/images/library-section/bongabong-periodical.jpg" title=">Bongabong Campus" alt=">Bongabong Campus">
                <div class="absolute bottom-0 w-full text-center bg-green-700 bg-opacity-40 py-4">
                  <p class="text-xl text-white">Bongabong Campus</p>
                </div>
              </div>
            </div>
          </container>
        </section>
        <section class="bg-gray-700 bg-opacity-50">
          <container class="py-20">
            <div class="sm:text-right pr-2 mb-6 flex justify-end items-center sm:p-6 p-4">
              <div>
                <h1 class="text-green-700 md:text-6xl  text-5xl lg:text-8xl font-bold uppercase">Medical</h1>
                <p class="font-thin text-4xl block uppercase ">Books</p>
              </div>
            </div>
            <div class="lg:flex justify-center">
              <div class="m-4 relative rounded-2xl shadow-2xl h-96 w-80 lg:w-80 sm:w-full  border-2 border-white overflow-hidden">
                <img class="h-96  lg:w-80 w-full object-cover object-center" src="../../assets/images/library-section/calapan-medical.jpg" title=">Calapan City Campus" alt=">Calapan City Campus">
                <div class="absolute bottom-0 w-full text-center bg-green-700 bg-opacity-40 py-4">
                  <p class="text-xl text-white">Calapan City Campus</p>
                </div>
              </div>
              <div class="m-4 relative rounded-2xl shadow-2xl h-96 w-80 lg:w-80 sm:w-full  border-2 border-white overflow-hidden">
                <img class="h-96  lg:w-80 w-full object-cover object-center" src="../../assets/images/library-section/calapan-medical1.jpg" title=">Calapan City Campus" alt=">Calapan City Campus">
                <div class="absolute bottom-0 w-full text-center bg-green-700 bg-opacity-40 py-4">
                  <p class="text-xl text-white">Calapan City Campus</p>
                </div>
              </div>
            </div>
          </container>
        </section>
        <section class="">
          <container class="py-20">
            <div class="sm:text-right pr-2 mb-6 flex items-center sm:p-6 p-4">
              <div>
                <h1 class="text-green-700 md:text-6xl  text-5xl lg:text-8xl font-bold uppercase">Fiction</h1>
                <p class="font-thin text-4xl block uppercase ">Books</p>
              </div>
            </div>
            <div class="lg:flex justify-center">
              <div class="m-4 relative rounded-2xl shadow-2xl h-96 w-80 lg:w-80 sm:w-full  border-2 border-white overflow-hidden">
                <img class="h-96  lg:w-80 w-full object-cover object-center" src="../../assets/images/library-section/main-fiction.jpg" title=">Main Campus" alt=">Calapan City Campus">
                <div class="absolute bottom-0 w-full text-center bg-green-700 bg-opacity-40 py-4">
                  <p class="text-xl text-white">Main Campus</p>
                </div>
              </div>
              <div class="m-4 relative rounded-2xl shadow-2xl h-96 w-80 lg:w-80 sm:w-full  border-2 border-white overflow-hidden">
                <img class="h-96  lg:w-80 w-full object-cover object-center" src="../../assets/images/library-section/calapan-fiction.jpg" title=">Calapan City Campus" alt=">Calapan City Campus">
                <div class="absolute bottom-0 w-full text-center bg-green-700 bg-opacity-40 py-4">
                  <p class="text-xl text-white">Calapan City Campus</p>
                </div>
              </div>
              <div class="m-4 relative rounded-2xl  shadow-2xl h-96 w-80 lg:w-80 sm:w-full  border-2 border-white overflow-hidden">
                <img class="h-96  lg:w-80 w-full object-cover object-center" src="../../assets/images/library-section/bongabong-fiction.jpg" title=">Bongabong Campus" alt=">Bongabong Campus">
                <div class="absolute bottom-0 w-full text-center bg-green-700 bg-opacity-40 py-4">
                  <p class="text-xl text-white">Bongabong Campus</p>
                </div>
              </div>
            </div>
          </container>
        </section>
        <section class="">
          <container class="py-20">
            <div class="sm:text-right pr-2 mb-6 flex items-center sm:p-6 p-4">
              <div>
                <h1 class="text-green-700 md:text-6xl  text-5xl lg:text-8xl font-bold uppercase">Reading</h1>
                <p class="font-thin text-4xl block uppercase ">Area</p>
              </div>
            </div>
            <div class="lg:flex justify-center">
              <div class="m-4 relative rounded-2xl shadow-2xl h-96 w-80 lg:w-80 sm:w-full  border-2 border-white overflow-hidden">
                <img class="h-96  lg:w-80 w-full object-cover object-center" src="../../assets/images/library-section/main-reading-area.jpg" title=">Main Campus" alt=">Main Campus">
                <div class="absolute bottom-0 w-full text-center bg-green-700 bg-opacity-40 py-4">
                  <p class="text-xl text-white">Main Campus</p>
                </div>
              </div>
              <div class="m-4 relative rounded-2xl shadow-2xl h-96 w-80 lg:w-80 sm:w-full  border-2 border-white overflow-hidden">
                <img class="h-96  lg:w-80 w-full object-cover object-center" src="../../assets/images/library-section/calapan-reading-area.jpg" title=">Calapan City Campus" alt=">Calapan City Campus">
                <div class="absolute bottom-0 w-full text-center bg-green-700 bg-opacity-40 py-4">
                  <p class="text-xl text-white">Calapan City Campus</p>
                </div>
              </div>
              <div class="m-4 relative rounded-2xl  shadow-2xl h-96 w-80 lg:w-80 sm:w-full  border-2 border-white overflow-hidden">
                <img class="h-96  lg:w-80 w-full object-cover object-center" src="../../assets/images/library-section/bongabong-reading-area.jpg" title=">Bongabong Campus" alt=">Bongabong Campus">
                <div class="absolute bottom-0 w-full text-center bg-green-700 bg-opacity-40 py-4">
                  <p class="text-xl text-white">Bongabong Campus</p>
                </div>
              </div>
            </div>
          </container>
        </section>
        <section class="bg-green-800 bg-opacity-30">
          <container class="py-20">
            <div class="sm:text-right pr-2 mb-6 flex items-center sm:p-6 p-4">
              <div>
                <h1 class="text-green-700 md:text-6xl  text-5xl lg:text-8xl font-bold uppercase">Multimedia</h1>
                <p class="font-thin text-4xl block uppercase ">Section</p>
              </div>
            </div>
            <div class="lg:flex justify-center">
              <div class="m-4 relative rounded-2xl shadow-2xl h-96 w-80 lg:w-80 sm:w-full  border-2 border-white overflow-hidden">
                <img class="h-96  lg:w-80 w-full object-cover object-center" src="../../assets/images/library-section/calapan-multimedia.jpg" title=">Calapan City Campus" alt=">Calapan City Campus">
                <div class="absolute bottom-0 w-full text-center bg-green-700 bg-opacity-40 py-4">
                  <p class="text-xl text-white">Calapan City Campus</p>
                </div>
              </div>
              <div class="m-4 relative rounded-2xl shadow-2xl h-96 w-80 lg:w-80 sm:w-full  border-2 border-white overflow-hidden">
                <img class="h-96  lg:w-80 w-full object-cover object-center" src="../../assets/images/library-section/calapan-multimedia1.jpg" title=">Calapan City Campus" alt=">Calapan City Campus">
                <div class="absolute bottom-0 w-full text-center bg-green-700 bg-opacity-40 py-4">
                  <p class="text-xl text-white">Calapan City Campus</p>
                </div>
              </div>
            </div>
          </container>
        </section>
        <section class="">
          <container class="py-20">
            <div class="sm:text-right pr-2 mb-6 flex items-center sm:p-6 p-4">
              <div>
                <h1 class="text-green-700 md:text-6xl  text-5xl lg:text-8xl font-bold uppercase">E-Library</h1>
                <p class="font-thin text-4xl block uppercase ">Room</p>
              </div>
            </div>
            <div class="lg:flex justify-center">
              <div class="m-4 relative rounded-2xl shadow-2xl h-96 w-80 lg:w-80 sm:w-full  border-2 border-white overflow-hidden">
                <img class="h-96  lg:w-80 w-full object-cover object-center" src="../../assets/images/library-section/main-elib.jpg" title=">Main Campus" alt=">Main Campus">
                <div class="absolute bottom-0 w-full text-center bg-green-700 bg-opacity-40 py-4">
                  <p class="text-xl text-white">Main Campus</p>
                </div>
              </div>
              <div class="m-4 relative rounded-2xl shadow-2xl h-96 w-80 lg:w-80 sm:w-full  border-2 border-white overflow-hidden">
                <img class="h-96  lg:w-80 w-full object-cover object-center" src="../../assets/images/library-section/calapan-elib.jpg" title=">Calapan City Campus" alt=">Calapan City Campus">
                <div class="absolute bottom-0 w-full text-center bg-green-700 bg-opacity-40 py-4">
                  <p class="text-xl text-white">Calapan City Campus</p>
                </div>
              </div>
              <div class="m-4 relative rounded-2xl  shadow-2xl h-96 w-80 lg:w-80 sm:w-full  border-2 border-white overflow-hidden">
                <img class="h-96  lg:w-80 w-full object-cover object-center" src="../../assets/images/library-section/bongabong-elib.jpg" title=">Bongabong Campus" alt=">Bongabong Campus">
                <div class="absolute bottom-0 w-full text-center bg-green-700 bg-opacity-40 py-4">
                  <p class="text-xl text-white">Bongabong Campus</p>
                </div>
              </div>
            </div>
          </container>
        </section>
      </div>
    </div>
  </basic-layout>
</template>

<script>
import BasicLayout from "../layouts/BasicLayout";
import Container from "../layouts/Container";
export default {
name: "LibrarySection",
  components: {Container, BasicLayout},
  data:() =>({
    section: {
      campus: "Main Campus",
      sections:{
        
      }
    }
  })
}
</script>

<style scoped>

</style>